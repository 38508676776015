import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    RouteComponentProps,
} from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

import Auth from "../auth/Auth";
import SignIn from "../pages/views/auth/SignIn";
import Page404 from "../pages/views/auth/Page404";
import { RouteInfoType } from "../types/types";
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
import Zendesk, { ZendeskAPI } from "./ZendexConfig";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
    routes.map(({ component: Component, children, path }, index: number) => {
        return children ? (
            children.map((element, index: number) => (
                <Route
                    key={index}
                    path={element.path}
                    exact
                    render={(props: RouteComponentProps) => (
                        <Layout>
                            <element.component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : Component ? (
            <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        ) : null;
    });

const Routes = () => {
    const handleLoaded = () => {
      ZendeskAPI("messenger", "close");
    };
    return (
    <Router>
        <Switch>
            <Route
                exact
                path="/signin"
                component={(props: RouteComponentProps) => (
                    <AuthLayout>
                        <SignIn {...props} />
                    </AuthLayout>
                )}
            />
            {/* <Route exact path="/singup" component={SignUp} /> */}
            <Auth>
                <Switch>
                    {childRoutes(DashboardLayout, dashboardRoutes)}
                    {childRoutes(AuthLayout, authRoutes)}
                    <Route
                        render={() => (
                            <AuthLayout>
                                <Page404 />
                            </AuthLayout>
                        )}
                    />
                </Switch>
                <div>
                <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
                </div>
            </Auth>
        </Switch>
    </Router>
);
                        };

export default Routes;
